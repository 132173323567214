<template>
  <div class="feedback">
    <img class="close-feedback" src="../assets/tipp/tipp-cross.svg" @click="$router.go(-1)">
    <LanguageSwitch class="lang-switch"></LanguageSwitch>
    <div class="textsheet textsheettitle">
      <h3>{{$t('feedback.title')}}</h3>
      <p class="confirmation" v-if="mess">{{mess}}</p>
    </div>
    <form @submit.prevent="formSubmit" id="feedbackform">
      <div class="textsheet textsheetform">
        <input type="text" v-model="form.pre" name="prenom" :placeholder="$t('feedback.form.pre', ['*'])" required>
        <input type="text" v-model="form.last" name="lastnom" :placeholder="$t('feedback.form.last', ['*'])" required>
        <input type="text" v-model="form.comp" name="company" :placeholder="$t('feedback.form.comp', ['*'])" required>
        <input type="text" v-model="form.add" name="address" :placeholder="$t('feedback.form.add', [''])">
        <input type="text" v-model="form.plz" name="plz" :placeholder="$t('feedback.form.plz', [''])">
        <input type="text" v-model="form.place" name="place" :placeholder="$t('feedback.form.place', [''])">
        <input type="email" v-model="form.mail" name="email" :placeholder="$t('feedback.form.mail', ['*'])" required>
        <input type="tel" v-model="form.tel" name="tel" :placeholder="$t('feedback.form.tel', [''])">
      </div>

      <div class="textsheet textsheetwhole">
        <textarea rows="5" name="message" v-model="form.mess" :placeholder="$t('feedback.form.mess', ['*'])" required></textarea><br>
        <button v-if="loading == false" name="send" type="submit">{{ $t('feedback.form.btn') }}</button>
        <p v-else>{{ $t('feedback.wait') }}</p>
      </div>
    </form>
  </div>
</template>

<script>
import LanguageSwitch from "@/components/LanguageSwitch";

export default {
  name: "feedback",
  components: {
    LanguageSwitch
  },
  data() {
    return {
      loading: false,
      mess: '',
      form: {
        pre: "",
        last: "",
        comp: "",
        add: "",
        plz: "",
        place: "",
        mail: "",
        tel: "",
        mess: ""
      }
    }
  },
  methods: {
    formSubmit() {
      this.loading = true

      var formdata = new FormData();
      formdata.append("send", "true");
      formdata.append("prenom", this.form.pre);
      formdata.append("lastnom", this.form.last);
      formdata.append("company", this.form.comp);
      formdata.append("address", this.form.add);
      formdata.append("plz", this.form.plz);
      formdata.append("place", this.form.place);
      formdata.append("tel", this.form.tel);
      formdata.append("email", this.form.mail);
      formdata.append("message", this.form.mess);


      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch(location.origin + "/feedback.php", requestOptions)
          .then(response => response.text())
          .then(result => {
            this.mess = this.$t('feedback.success')
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.mess = this.$t('feedback.fail')
          });
    }
  },
}
</script>

<style lang="scss" scoped>
.lang-switch {
  position: absolute;
  top: 0;
  right: 10%;
}

.feedback {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 70px;

  @include tablet-down {
    padding: 25px;
  }
}

.confirmation {
  width: 100%;
  margin-top: 30px;
  color: #BAD9BB;
}

.close-feedback {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 45px;
  cursor: pointer;

  @include tablet-down {
    width: 35px;
    z-index: 5;
  }
}

.textsheettitle {
  width: 80%;
}

.textsheetwhole {
  padding-right: 40px;
}

#feedbackform {
  width: 85%;

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  {
    border: none;
    width: calc(50% - 40px);
    border-bottom: black 3px solid;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 40px;
    padding-bottom: 8px;
    padding-top: 8px;
    font-family: "HKSentiments-bold", sans-serif;
    font-size: 20px;

    @include tablet-down {
      width: 100%;
    }
  }

  textarea {
    border: none;
    width: 100%;
    border-bottom: black 3px solid;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 8px;
    padding-top: 8px;
    font-family: "HKSentiments-bold", sans-serif;
    font-size: 20px;
  }

  button {
    border: none;
    background-color: #C7B299;
    color: white;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "HKSentiments-black", sans-serif;
    font-size: 20px;
    margin-top: 25px;
    cursor: pointer;
  }
}
</style>
